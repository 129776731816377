/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    url: "/",
    name: "Home",
    slug: "home",
    icon: "HomeIcon",
  },
  {
    url: null,
    name: "Setting",
    icon: "SettingsIcon",
    submenu: [
      {
        url: {name:'application'},
        name: 'Application',
        slug: 'application',
      },
      {
        url: {name:'areas'},
        name: 'Areas',
        slug: 'areas',
      },
      {
        url: {name:'companies'},
        name: 'Companies',
        slug: 'companies',
      },
      {
        url: {name:'users'},
        name: 'User Management',
        slug: 'users',
      },
      {
        url: {name:'roles'},
        name: 'Role',
        slug: 'roles',
      },
      {
        url: null,
        name: 'General Setting',
        slug: 'general-setting',
        submenu: [
          // {
          //   url: {name:'transaction_types'},
          //   name: 'Transaction Type',
          //   slug: 'transaction_types',
          // },
          {
            url: {name:'commodities'},
            name: 'Commodities',
            slug: 'commodities',
          },
          {
            url: {name:'units'},
            name: 'Units',
            slug: 'units',
          },
          {
            url: {name:'container_types'},
            name: 'Container Type',
            slug: 'container_types',
          },
          {
            url: {name:'vehicle_types'},
            name: 'Vehicle Type',
            slug: 'vehicle_types',
          },
          {
            url: {name:'taxes'},
            name: 'Taxes and VAT',
            slug: 'taxes',
          },
          {
            url: '/setting/items-product',
            name: 'Items Product',
            slug: 'items-product',
            submenu: [
              {
                url: {name:'car_factories'},
                name: 'Car Factory',
                slug: 'car_factories'
              },
              {
                url: {name:'car_colors'},
                name: 'Exterior',
                slug: 'car_colors'
              },
              {
                url: {name:'interiors'},
                name: 'Interior',
                slug: 'interiors'
              },
              {
                url: {name:'car_types'},
                name: 'Car Type',
                slug: 'car_types'
              },
              {
                url: {name:'car_spareparts'},
                name: 'Car Sparepart',
                slug: 'car_spareparts'
              }
            ]
          }
        ]
      },
      {
        url: null,
        name: 'Operational',
        slug: 'operational',
        submenu: [
          {
            url: {name:'costs'},
            name: 'Costs',
            slug: 'costs',
          },
          {
            url: {name:'routes'},
            name: 'Routes',
            slug: 'routes',
          }
        ]
      }
    ]
  },
  {
    url: null,
    name: "Contact",
    icon: "UsersIcon",
    submenu: [
      {
        url: {name:'contacts'},
        name: 'All Contacts',
        slug: 'contacts',
      },
    ]
  },
  {
    url: null,
    name: "Vehicles",
    icon: "TruckIcon",
    submenu: [
      {
        url: {name:'vehicles'},
        name: 'Vehicles List',
        slug: 'vehicles',
      },
    ]
  },
  {
    url: null,
    name: "Marketing",
    icon: "PhoneCallIcon",
    submenu: [
      {
        url: {name:'price_lists'},
        name: 'Price Lists',
        slug: 'price_lists',
      },
      {
        url: {name:'contracts'},
        name: 'Contracts',
        slug: 'contracts',
      }
    ]
  },
  {
    url: null,
    name: "Driver",
    icon: "UsersIcon",
    submenu: [
      {
        url: {name:'driver_contacts'},
        name: 'Drivers',
        slug: 'drivers',
      },
      {
        url: {name:'driver_absences'},
        name: 'Driver Absences',
        slug: 'driver_absences',
      },
    ]
  },
  {
    url: null,
    name: "Operational",
    icon: "CpuIcon",
    submenu: [
      {
        url: {name:'job_orders'},
        name: 'Job Orders',
        slug: 'job_orders',
      },
      {
        url: {name:'manifests'},
        name: 'Packing Lists',
        slug: 'manifests',
      },
      {
        url: {name:'shipments'},
        name: 'Driver Shipments',
        slug: 'shipments',
      },
      {
        url: {name:'invoices'},
        name: 'Invoices',
        slug: 'invoices',
      },
      {
        url: {name:'map_shipments'},
        name: 'Map Shipment',
        slug: 'map_shipments',
      },
      {
        url: null,
        name: 'Customer Payment',
        slug: 'customer-payments',
        submenu: [
          {
            url: { name: 'receivables' },
            name: 'Receivables List',
            slug: 'receivables'
          },
          {
            url: { name: 'bills' },
            name: 'Payment',
            slug: 'bills'
          }
        ]
      },
      {
        url: null,
        name: 'Vendor Payment',
        slug: 'vendor-payments',
        submenu: [
          {
            url: { name: 'payables' },
            name: 'Payables List',
            slug: 'payables'
          },
          {
            url: { name: 'debts' },
            name: 'Payment',
            slug: 'debts'
          }
        ]
      },
      {
        url: null,
        name: 'Reports',
        slug: 'reports',
        submenu: [
          {
            url: { name: 'car-shipment' },
            name: 'Car Shipment',
            slug: 'car-shipment'
          },
          {
            url: { name: 'sparepart_shipments' },
            name: 'Sparepart Shipment',
            slug: 'sparepart_shipments'
          },
          {
            url: { name: 'drivers' },
            name: 'Drivers',
            slug: 'drivers'
          },
          {
            url: { name: 'driver_performances' },
            name: 'Driver Performance',
            slug: 'driver_performances'
          }
        ]
      }
    ]
  }
]
